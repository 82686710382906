import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es";

const contacto = () => {
	const forms = document.querySelectorAll(".agenda-block form");

	if (forms.length > 0) {
		forms.forEach((forma) => {
			const nombre = forma.querySelector("#nombre");
			const tel = forma.querySelector("#tel");
			const fechaevento = forma.querySelector("#fechaevento");
			const cantidad = forma.querySelector("#cantidad");

			flatpickr(fechaevento, {
				locale: Spanish,
				formatDate: (date) => date.toString(),
			});

			forma.addEventListener("submit", function (e) {
				if (
					fechaevento.value === "" ||
					fechaevento.value === null ||
					fechaevento.value === false ||
					fechaevento.value === undefined
				) {
					e.preventDefault();
					fechaevento.classList.add("is-invalid");
				} else {
					fechaevento.classList.remove("is-invalid");
				}

				if (
					cantidad.value === "" ||
					cantidad.value === null ||
					cantidad.value === false
				) {
					e.preventDefault();
					cantidad.classList.add("is-invalid");
				} else {
					cantidad.classList.remove("is-invalid");
				}

				if (
					nombre.value === "" ||
					nombre.value === null ||
					nombre.value === false
				) {
					e.preventDefault();
					nombre.classList.add("is-invalid");
				} else {
					nombre.classList.remove("is-invalid");
				}

				if (tel.value === "" || tel.value === null || tel.value === false) {
					e.preventDefault();
					tel.classList.add("is-invalid");
				} else {
					tel.classList.remove("is-invalid");
				}
			});
		});
	}
};

export default contacto;
