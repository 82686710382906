import AOS from "aos";
import MagicGrid from "magic-grid";
import Spotlight from "spotlight.js";
import Splitting from "splitting";
import { gsap } from "gsap";
import Headroom from "headroom.js";
import MicroModal from "micromodal";
import contacto from "./contacto";

document.addEventListener("DOMContentLoaded", function () {
	/*
	-------------------------------
	Intro
	-------------------------------
	*/
	Splitting();

	const bannerVideo = document.querySelector(".main-banner video");
	const triUp = document.querySelector(".main-banner .fig-1");
	const triDown = document.querySelector(".main-banner .fig-2");

	const intro = gsap.timeline({ paused: true });

	intro.set(bannerVideo, { opacity: 0 });

	intro
		.from(".main-banner .heading .word", {
			opacity: 0,
			stagger: 0.5,
			duration: 1,
			y: 10,
			ease: "power3.out",
			delay: 0.75,
		})
		.from(
			triUp,
			{ opacity: 0, duration: 2, y: 50, ease: "power3.out" },
			"<-.5"
		)
		.from(
			triDown,
			{ opacity: 0, duration: 1, y: -50, ease: "power3.out" },
			"<"
		)
		.to(bannerVideo, { opacity: 1, duration: 5 }, "<+1");

	intro.play();

	/*
	-------------------------------
	Animaciones scroll
	-------------------------------
	*/
	AOS.init();

	var scrollRef = 0;
	window.addEventListener("scroll", function () {
		// increase value up to 10, then refresh AOS
		if (scrollRef <= 10) {
			scrollRef++;
		} else {
			AOS.refresh();
		}
	});

	/*
	-------------------------------
	Header
	-------------------------------
	*/
	const navBtn = document.querySelector(".nav .hamburger");
	const navDrop = document.querySelector(".nav .nav-dropdown");

	navBtn.addEventListener("click", () => {
		navBtn.classList.toggle("is-active");
		navDrop.classList.toggle("is-active");
	});

	const header = document.querySelector(".main-header");
	const headroom = new Headroom(header, {
		// vertical offset in px before element is first unpinned
		offset: 0,
		// or you can specify offset individually for up/down scroll
		offset: {
			up: 100,
			down: 50,
		},
		// scroll tolerance in px before state changes
		tolerance: 0,
		// or you can specify tolerance individually for up/down scroll
		tolerance: {
			up: 5,
			down: 0,
		},
		// css classes to apply
		classes: {
			// when element is initialised
			initial: "headroom",
			// when scrolling up
			pinned: "headroom--pinned",
			// when scrolling down
			unpinned: "headroom--unpinned",
			// when above offset
			top: "headroom--top",
			// when below offset
			notTop: "headroom--not-top",
			// when at bottom of scroll area
			bottom: "headroom--bottom",
			// when not at bottom of scroll area
			notBottom: "headroom--not-bottom",
			// when frozen method has been called
			frozen: "headroom--frozen",
		},
		onPin: function () {
			navBtn.classList.remove("is-active");
			navDrop.classList.remove("is-active");
		},
		onUnpin: function () {
			navBtn.classList.remove("is-active");
			navDrop.classList.remove("is-active");
		},
	});

	headroom.init();

	/*
	-------------------------------
	Galeria
	-------------------------------
	*/
	let magicGridGaleria = new MagicGrid({
		container: ".galeria .grid", // Required. Can be a class, id, or an HTMLElement.
		static: true, // Required for static content.
		animate: true, // Optional.
		gutter: 0,
		useMin: true,
	});

	magicGridGaleria.listen();

	/*
	-------------------------------
	Catering
	-------------------------------
	*/
	let magicGridCatering = new MagicGrid({
		container: ".catering .grid", // Required. Can be a class, id, or an HTMLElement.
		static: true, // Required for static content.
		animate: true, // Optional.
		gutter: 0,
		useMin: true,
	});

	magicGridCatering.listen();

	/*
	-------------------------------
	Contacto
	-------------------------------
	*/
	contacto();

	/*
	-------------------------------
	Video modal
	-------------------------------
	*/
	const videofull = document.querySelector("#videofull");

	MicroModal.init({
		awaitCloseAnimation: true, // set to false, to remove close animation
		onShow: function (modal) {
			videofull.play();
		},
		onClose: function (modal) {
			videofull.pause();
		},
	});
});
